<template>
  <Card>
    <template #content>
      <Toolbar class="mb-4">
        <template #start>
          <Button
            label="Voltar"
            icon="pi pi-chevron-left"
            class="mt-2 mr-2 p-button-sm p-button-secondary"
            @click="$router.back()" />
          <Button
            v-if="
              consignacao.propostaConsignacao !== null &&
              consignacao.propostaConsignacao.id !== null
            "
            title="Proposta"
            icon="pi pi-file"
            class="mt-2 mr-2 p-button-sm"
            @click="exibirDetalheProposta()" />
          <Button
            title="Gerar Comprovante de Autorização"
            class="mt-2 mr-2 p-button-sm"
            @click="gerarComprovanteConsignacao()">
            <span v-if="!loadingPdf" class="pi pi-print"></span>
            <span v-if="loadingPdf" class="pi pi-spin pi-spinner"></span>
            <span v-if="!loadingPdf"></span>
            <span v-if="loadingPdf">Aguarde</span>
          </Button>
        </template>
      </Toolbar>
      <div class="p-fluid formgrid grid">
        <div class="field col-6 md:col-6">
          <label for="rubrica">Rubrica</label>
          <InputText id="rubrica" v-model="dadosRubrica" disabled />
        </div>
        <div
          v-if="
            consignacao.propostaConsignacao !== null &&
            (consignacao.propostaConsignacao.agencia !== 'undefined' ||
              consignacao.propostaConsignacao.agencia !== null)
          "
          class="field col-3 md:col-3">
          <label for="rubrica">Nº Contrato</label>
          <InputText
            id="rubrica"
            v-model="consignacao.numeroContrato"
            disabled />
        </div>
        <div v-else class="field col-6 md:col-6">
          <label for="rubrica">Nº Contrato</label>
          <InputText
            id="rubrica"
            v-model="consignacao.numeroContrato"
            disabled />
        </div>
        <div
          v-if="
            consignacao.propostaConsignacao !== null &&
            (consignacao.propostaConsignacao.agencia !== 'undefined' ||
              consignacao.propostaConsignacao.agencia !== null)
          "
          class="field col-3 md:col-3">
          <label for="agencia">Agência</label>
          <InputText
            id="agencia"
            v-model="consignacao.propostaConsignacao.agencia"
            type="text"
            disabled />
        </div>
      </div>
      <div class="p-fluid formgrid grid">
        <div class="field col-6 md:col-6">
          <label for="valor">Valor da Parcela</label>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">R$</span>
            <InputText id="valor" v-model="consignacao.valorParcela" disabled />
          </div>
        </div>
        <div class="field col-6 md:col-6">
          <label for="situacao">Situação</label>
          <InputText
            id="situacao"
            v-model="consignacao.statusConsignacaoDto.nome"
            disabled />
        </div>
      </div>
      <div class="p-fluid formgrid grid">
        <div class="field col-6 md:col-6">
          <label for="prazoRestante">Prazo Restante</label>
          <InputText
            id="prazoRestante"
            v-model="consignacao.prazoRestante"
            disabled />
        </div>
        <div class="field col-6 md:col-6">
          <label for="prazoTotal">Prazo Total</label>
          <InputText
            id="prazoTotal"
            v-model="consignacao.prazoTotal"
            disabled />
        </div>
      </div>
      <div class="p-fluid formgrid grid">
        <div class="field col-6 md:col-6">
          <label for="ano">Ano</label>
          <InputText id="ano" v-model="consignacao.anoReferencia" disabled />
        </div>
        <div class="field col-6 md:col-6">
          <label for="mes">Mês</label>
          <InputText id="mes" v-model="consignacao.mesReferencia" disabled />
        </div>
      </div>
      <div v-if="prestacao" class="p-fluid formgrid grid">
        <div class="field col-2 md:col-2">
          <label for="valor">Valor da Prestação</label>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">R$</span>
            <InputText id="valor" v-model="prestacao.valor" disabled />
          </div>
        </div>
        <div class="field col-2 md:col-2">
          <label for="prazoTotal">Ano da Prestação</label>
          <InputText
            id="prazoTotal"
            v-model="prestacao.anoReferencia"
            disabled />
        </div>
        <div class="field col-2 md:col-2">
          <label for="prazoTotal">Mês da Prestação</label>
          <InputText
            id="prazoTotal"
            v-model="prestacao.mesReferencia"
            disabled />
        </div>
      </div>
      <div class="p-fluid formgrid grid">
        <div class="field col-6 md:col-6">
          <label for="cadastradoPor">Cadastrado Por</label>
          <InputText
            v-if="
              consignacao.cadastradoPor !== null &&
              consignacao.propostaConsignacao === null
            "
            id="cadastradoPor"
            v-model="consignacao.cadastradoPor.nome"
            disabled />
          <InputText
            v-else-if="
              consignacao.propostaConsignacao !== null &&
              (consignacao.propostaConsignacao.cadastradoPor !== 'undefined' ||
                consignacao.propostaConsignacao.cadastradoPor !== null)
            "
            id="cadastradoPor"
            v-model="consignacao.propostaConsignacao.cadastradoPor.nome"
            disabled />
          <InputText v-else id="cadastradoPor" disabled />
        </div>
        <div class="field col-6 md:col-6">
          <label for="cadastradoEm">Cadastrado Em</label>
          <InputText
            v-if="consignacao.auditMetadata !== undefined"
            id="cadastradoEm"
            :value="consignacao.auditMetadata.cadastradoEm | formatarData"
            disabled />
        </div>
      </div>
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-12">
          <label for="observacao">Observação</label>
          <Textarea
            id="observacao"
            v-model="consignacao.observacoes"
            :autoResize="true"
            disabled />
        </div>
      </div>
      <DataTable
        class="p-datatable-sm"
        :paginator="true"
        :rows="5"
        stripedRows
        :loading="loading"
        :value="historicoConsignacao"
        dataKey="id"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[5, 10, 25]"
        currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} consignações"
        responsiveLayout="scroll">
        <template #header>Histórico Consignação</template>
        <template #empty> Nenhum histórico encontrado. </template>
        <template #loading> Carregando. Por favor aguarde. </template>
        <Column header="Cadastrado Em">
          <template #body="{ data }">
            {{ data ? data.cadastradoEm : '-' | formatarData }}
          </template>
        </Column>
        <Column header="Cadastrado Por">
          <template #body="{ data }">
            {{ data.cadastradoPor }}
          </template>
        </Column>
        <Column
          :sortable="true"
          header="Situação"
          :styles="{ 'min-width': '12rem' }">
          <template #body="{ data }">
            <Chip
              v-if="data.tipo === 'PENDENTE'"
              label="Pendente"
              class="mr-2 mb-2 custom-chip pendente" />
            <Chip
              v-if="data.tipo === 'ATIVA'"
              label="Ativa"
              class="mr-2 mb-2 custom-chip ativa" />
            <Chip
              v-if="data.tipo === 'QUITADA'"
              label="Quitada"
              class="mr-2 mb-2 custom-chip quitada" />
            <Chip
              v-if="data.tipo === 'CANCELADA'"
              label="Cancelada"
              class="mr-2 mb-2 custom-chip cancelada" />
            <Chip
              v-if="data.tipo === 'SUSPENSA'"
              label="Suspensa"
              class="mr-2 mb-2 custom-chip suspensa" />
            <Chip
              v-if="data.tipo === 'AGUARDANDO_SUPERVIDOR'"
              label="Aguardando Supervisor"
              class="mr-2 mb-2 custom-chip aguardando-supervisor" />
            <Chip
              v-if="data.tipo === 'NAO_APROVADA_SUPERVISOR'"
              label="Não Aprovada Supervisor"
              class="mr-2 mb-2 custom-chip não-aprovada-supervisor" />
            <Chip
              v-if="data.tipo === 'BAIXADA'"
              label="Baixada"
              class="mr-2 mb-2 custom-chip baixada" />
            <Chip
              v-if="data.tipo === 'NAO_DESCONTADA'"
              label="Não descontada"
              class="mr-2 mb-2 custom-chip não-descontada" />
            <Chip
              v-if="data.tipo === 'SOLICITADO_CANCELAMENTO'"
              label="Cancelada"
              class="mr-2 mb-2 custom-chip cancelada" />
            <Chip
              v-if="data.tipo === 'SOLICITADA_SUSPENSAO'"
              label="Suspensa"
              class="mr-2 mb-2 custom-chip suspensa" />
            <Chip
              v-if="data.tipo === 'MOVIMENTO_PARCIAL'"
              label="Mov. Parcial"
              class="mr-2 mb-2 custom-chip foda-da-margem" />
            <Chip
              v-if="data.tipo === 'FORA_MARGEM'"
              label="Fora da margem"
              class="mr-2 mb-2 custom-chip foda-da-margem" />
            <Chip
              v-if="data.tipo === 'DESCONTO_A_MENOR'"
              label="Desc. a Menor"
              class="mr-2 mb-2 custom-chip foda-da-margem" />
          </template>
        </Column>
        <Column header="Motivo">
          <template #body="{ data }">
            {{ data.motivo ? data.motivo : '-' }}
          </template>
        </Column>
        <Column header="Observação">
          <template #body="{ data }">
            {{ data.observacao ? data.observacao : '-' }}
          </template>
        </Column>
      </DataTable>
    </template>
  </Card>
</template>

<script>
import { saveAs } from 'file-saver'
import ConsignacaoService from '@/service/ConsignacaoService'
import PrestacaoService from '@/service/PrestacaoService'
import HistoricoConsignacaoService from '@/service/HistoricoConsignacaoService'
import Consignacao from '@/domain/Consignacao.js'
import Relatorio from '@/domain/Relatorio.js'
import RelatorioService from '@/service/RelatorioService.js'

export default {
  data() {
    return {
      relatorio: new Relatorio(),
      consignacao: new Consignacao(),
      prestacao: null,
      id: this.$route.params.idconsig,
      dadosRubrica: '',
      historicoConsignacao: [
        {
          cadastadoPor: '',
          cadastradoEm: '',
          tipo: '-',
          motivo: '-',
          observacao: '-',
        },
      ],
      loading: false,
      loadingPdf: false,
    }
  },

  created() {
    this.service = new ConsignacaoService(this.$http)
    this.historicoConsignacaoService = new HistoricoConsignacaoService(
      this.$http,
    )
    this.prestacaoService = new PrestacaoService(this.$http)
    this.relatorioService = new RelatorioService(this.$http)
  },

  mounted() {
    this.carregarConsignacao()
    this.carregarHistoricoConsignacao()
  },

  methods: {
    carregarConsignacao() {
      this.service
        .getConsignacaoById(this.id)
        .then((res) => {
          this.consignacao = res
          this.dadosRubrica =
            this.consignacao.rubrica.rubrica +
            ' | ' +
            this.consignacao.rubrica.nome

          if (
            this.consignacao.statusConsignacao === 'MOVIMENTO_PARCIAL' ||
            this.consignacao.statusConsignacao === 'DESCONTO_A_MENOR'
          ) {
            this.carregarPrestacao()
          }
        })
        .catch((err) => {
          this.$toast.add({
            severity: 'error',
            summary: err.response.data.message,
            life: 10000,
          })
        })
    },

    carregarPrestacao() {
      this.prestacaoService
        .getPrestacaoPorIdConsignacao(this.consignacao.id)
        .then((res) => {
          this.prestacao = res
        })
        .catch((err) => {
          this.$toast.add({
            severity: 'error',
            summary: err.response.data.message,
            life: 10000,
          })
        })
    },

    carregarHistoricoConsignacao() {
      this.loading = true
      this.historicoConsignacaoService
        .getHistoricoConsignacao(this.id)
        .then((res) => {
          this.historicoConsignacao = res
          this.loading = false
        })
        .catch((err) => {
          this.$toast.add({
            severity: 'error',
            summary: err.response.data.message,
            life: 10000,
          })
        })
    },

    gerarComprovanteConsignacao() {
      this.relatorio.tipo = 'AUTORIZACAO'
      this.relatorio.nome = 'autorizacaoConsignacao'
      this.relatorio.contentType = 'PDF'
      this.relatorio.parametros = new Map()
      this.relatorio.parametros.set('consignacaoId', this.consignacao.id)
      this.loadingPdf = true
      this.relatorioService
        .gerarRelatorioViaJasper(this.relatorio)
        .then((res) => {
          this.downloadPdf(res, this.relatorio.nome)
          this.relatorio.parametros = new Map()
          this.loadingPdf = false
        })
    },

    downloadPdf(response, nomeRelatorio) {
      const blob = new Blob([response], {
        type: 'application/pdf',
      })

      saveAs(blob, nomeRelatorio + '.pdf')
    },

    downloadHtml(response, nomeRelatorio) {
      const blob = new Blob([response], {
        type: 'text/html',
      })

      saveAs(blob, nomeRelatorio + '.html')
    },

    downloadCsv(response, nomeRelatorio) {
      const blob = new Blob([response], {
        type: 'text/csv',
      })

      saveAs(blob, nomeRelatorio + '.xls')
    },

    exibirDetalheProposta() {
      this.$router.push({
        name: 'proposta-detalhe',
        params: { idproposta: this.consignacao.propostaConsignacao.id },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.pendente {
  color: #1f2d3d;
  background-color: #ffc107;
  font-weight: bold;
}

.ativa {
  color: #fff;
  background-color: #007bff;
  font-weight: bold;
}

.cancelada {
  color: #fff;
  background-color: #dc3545;
  font-weight: bold;
}

.suspensa {
  color: #1f2d3d;
  background-color: #c0c0c0;
  font-weight: bold;
}

.quitada {
  color: #fff;
  background-color: #28a745;
  font-weight: bold;
}
</style>
